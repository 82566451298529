/**
 * @license
 *
 * Font Family: Nippo
 * Designed by: Manushi Parikh
 * URL: https://www.fontshare.com/fonts/nippo
 * © 2024 Indian Type Foundry
 *
 * Font Styles:
 * Nippo Variable(Variable font)
 * Nippo Extralight
 * Nippo Light
 * Nippo Regular
 * Nippo Medium
 * Nippo Bold
 *
*/


/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
  font-family: 'Nippo-Variable';
  src: url('../fonts/Nippo-Variable.woff2') format('woff2'),
       url('../fonts/Nippo-Variable.woff') format('woff'),
       url('../fonts/Nippo-Variable.ttf') format('truetype');
       font-weight: 200 700;
       font-display: swap;
       font-style: normal;
}
